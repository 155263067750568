@import "src/styles";

.block {
  background-color: $white;
  max-width: $maxWidthDefault;
  margin: 0 auto;
}

.item {
  display: flex;
  align-items: center;
  padding-bottom: 10px;

  &:last-child {
    padding-bottom: 0;
  }


  .text {
    font-size: $fontSize14;
    line-height: $fontSize18;
    padding-inline-start: 10px;
    width: 95%;
  }
}

.featureBlock {
  background: #F7F7F7;
  height: 72px;
  width: 100%;
  display: flex;
  border-radius: 10px;
  padding: 10px;
  gap: 15px;
  align-items: center;
  margin-top: 10px;

  .emoji {
    font-size: 22px;
  }

  .featureTitle {
    font-weight: 500;
    color: #2D2D2D;
    font-size: 16px;
  }

  .featureDescription {
    font-weight: 500;
    color: #818181;
    font-size: 12px;
  }
}