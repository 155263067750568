@import 'src/styles';

.wrap {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 25px 15px 16px;
  align-items: flex-start;
  border-radius: 15px;
  box-shadow: $boxShadow;
}