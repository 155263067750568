@import "src/styles";

section.wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 1;
  max-width: $maxWidthDefault;

  .plansFeatures {
    margin-bottom: 20px;
  }

  h2 {
    margin-bottom: 24px;
    color: $plansSectionTitle;

    b {
      color: $plansPrimaryColor;
    }
  }

  &.fullScreen {
    max-width: $maxWidthFull;

    .slider {
      margin: -20px -16px 0;
      width: calc(100% + 32px);
    }
  }

  .container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

    &.gap {
      margin-top: 30px;
    }

    &.horizontal {
      .productsList {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
      }
    }
  }

  .productsList {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .reminder {
    color: rgba(242, 230, 196, 0.7);
    text-align: center;
    padding-top: 15px;

    b {
      color: #f2e6c4;
    }
  }

  .fixedBtnWrap {
    position: relative;
    width: 100%;
  }

  .guaranteedWrapper {
    margin-top: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .guaranteedText {
      max-width: 293px;
      font-weight: 400;
      font-size: 12px;
      color: #2D2D2D;
      text-align: center;
      margin-top: 24px;
    }

    .guaranteedLinkWrapper {
      margin-top: 9.67px;
    }
  }

  .guaranteedLink {
    font-size: 12px;
    line-height: 15px;
    letter-spacing: 0.04em;
    text-align: center;
    color: #ABABAB;
    text-decoration: underline;
  }

  .guaranteeSave {
    display: flex;
    gap: 10px;
    color: #2D2D2D;
    font-weight: $fwexb;
    font-size: $fontSize14;
    line-height: 19.07px;
    text-transform: uppercase;
    font-family: $fontFamily
  }

  .text {
    font-weight: $fwn;
    font-size: $fontSize11;
    line-height: $fontSize15;
  }
}

.standaloneSubtitle {
  b {
    color: #FF8777;
    font-weight: 700;
  }
}