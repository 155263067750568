@import "src/styles";

.totalSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  .totalTitle {
    font-size: 18px;
    font-weight: 700;
    color: #171717;
  }
  .priceDiscount {
    font-size: 14px;
    font-weight: 600;
    color: #818181;
    margin-right: 10px;
    text-decoration: line-through;
  }
  .discountHint {
    margin-top: 5px;
    color: #03BD12;
    font-size: 14px;
    font-weight: 700;
  }
}

.hint {
  margin-top: 10px;
  font-size: 12px;
  font-weight: 400;
  color: #2D2D2D;
}