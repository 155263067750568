@import "src/styles";

.block {
  background-color: $white;
  max-width: $maxWidthDefault;
  margin: 0 auto;
}

.totalSection {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 25px;
  padding-bottom: 17px;
  border-bottom: 1px solid #E3E3E3;

  .totalTitle {
    font-size: 18px;
    font-weight: 700;
    color: #171717;
  }

  .discountHint {
    color: #03BD12;
    font-size: 14px;
    font-weight: 700;
  }
}

.hint {
  margin-top: 10px;
  width: 100%;

  a {
    text-decoration: underline;
  }

  .hintTitle {
    font-weight: 400;
    font-size: 11px;
    line-height: 15px;
    letter-spacing: 0%;
    color: #2D2D2D;
    text-align: center;
    margin-bottom: 8px;

    a {
      color: #2D2D2D;
    }
  }

  .hintText {
    font-size: 11px;
    font-weight: 400;
    line-height: 15px;
    color: #818181;
    text-align: center;
    margin-bottom: 0;

    a {
      color: #818181;
    }
  }
}

.priceDiscount {
  display: flex;
  align-items: center;
  gap: 3px;
  font-size: $fontSize18;
  font-weight: $fwstb;
  line-height: $fontSize18;
  color: #03BD12;
}

.includedFeatures {
  display: flex;
  flex-direction: column;
  gap: 11px;
  width: 100%;
  margin-top: 13px;
  margin-bottom: 7px;

  .includedFeature {
    display: flex;
    justify-content: space-between;
    font-size: $fontSize14;
    font-weight: $fwn;
    line-height: $fontSize18;
  }

  .text {}

  .price {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 7px;
  }

  .priceLineThroughVal {
    font-size: $fontSize10;
    font-weight: $fwn;
    line-height: $fontSize18;
    color: #818181;
    text-decoration: line-through;
  }

  .priceVal {}
}